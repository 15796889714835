import React from "react";
import "./Programs.css";
import propgram1 from "../../assets/program-1.png";
import propgram2 from "../../assets/program-2.png";
import propgram3 from "../../assets/program-3.png";
import programIcon1 from "../../assets/program-icon-1.png";
import programIcon2 from "../../assets/program-icon-2.png";
import programIcon3 from "../../assets/program-icon-3.png";
const Programs = () => {
  return (
    <div className="programs">
      <div className="program">
        <img src={propgram1} alt="program1" />
        <div className="caption">
          <img src={programIcon1} alt="programIcon1" />
          <p>Graduation Degree</p>
        </div>
      </div>
      <div className="program">
        <img src={propgram2} alt="program2" />
        <div className="caption">
          <img src={programIcon2} alt="programIcon2" />
          <p>Master Degree</p>
        </div>
      </div>
      <div className="program">
        <img src={propgram3} alt="program3" />
        <div className="caption">
          <img src={programIcon3} alt="programIcon3" />
          <p>Post Degree</p>
        </div>
      </div>
    </div>
  );
};

export default Programs;
