import React, { useRef } from "react";
import "./Testimonials.css";
import nextIcon from "../../assets/next-icon.png";
import backIcon from "../../assets/back-icon.png";
import user1 from "../../assets/user-1.png";
import user2 from "../../assets/user-2.png";
import user3 from "../../assets/user-3.png";
import user4 from "../../assets/user-4.png";
const Testimonials = () => {
  const slider = useRef();
  let tx = 0;
  const slideForword = () => {
    if (tx > -50) {
      tx -= 25;
    }
    slider.current.style.transform = `translateX(${tx}%)`;
  };
  const slideBackword = () => {
    if (tx < 0) {
      tx += 25;
    }
    slider.current.style.transform = `translateX(${tx}%)`;
  };
  return (
    <div className="testimonials">
      <img
        src={nextIcon}
        alt="nextIcon"
        className="next-btn"
        onClick={slideForword}
      />
      <img
        src={backIcon}
        alt="backIcon"
        className="back-btn"
        onClick={slideBackword}
      />
      <div className="slider">
        <ul ref={slider}>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user1} alt="user1" />
                <div>
                  <h3>William Jackson1</h3>
                  <span>Edusity , USA</span>
                </div>
              </div>
              <p>
                Choosing to pursue my degreeat Edusity was one_ptF the best
                decisions I've ever made. The supportive community,
                state-of-the-art facilities, and commitment to academic
                excellence have trulyexceeded my expectations.
              </p>
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user2} alt="user2" />
                <div>
                  <h3>William Jackson2</h3>
                  <span>Edusity , USA</span>
                </div>
              </div>
              <p>
                Choosing to pursue my degreeat Edusity was one_ptF the best
                decisions I've ever made. The supportive community,
                state-of-the-art facilities, and commitment to academic
                excellence have trulyexceeded my expectations.
              </p>
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user3} alt="user3" />
                <div>
                  <h3>William Jackson3</h3>
                  <span>Edusity , USA</span>
                </div>
              </div>
              <p>
                Choosing to pursue my degreeat Edusity was one_ptF the best
                decisions I've ever made. The supportive community,
                state-of-the-art facilities, and commitment to academic
                excellence have trulyexceeded my expectations.
              </p>
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user4} alt="user4" />
                <div>
                  <h3>William Jackson4</h3>
                  <span>Edusity , USA</span>
                </div>
              </div>
              <p>
                Choosing to pursue my degreeat Edusity was one_ptF the best
                decisions I've ever made. The supportive community,
                state-of-the-art facilities, and commitment to academic
                excellence have trulyexceeded my expectations.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Testimonials;
