import React from "react";
import "./Contact.css";
import msgIcon from "../../assets/msg-icon.png";
import mail from "../../assets/mail-icon.png";
import phone from "../../assets/phone-icon.png";
import location from "../../assets/location-icon.png";
import whiteArrow from "../../assets/white-arrow.png";
const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "4f92b01b-97b3-485f-9d80-a043db3f06c4");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="contact">
      <div className="contact-col">
        <h3>
          send us a message <img src={msgIcon} alt="msgIcon" />
        </h3>
        <p>
          {" "}
          free to reach out through contact form or find our contact information
          below. Your feedback, questions, and suggestions are important to us
          as we strive to provide exceptional service to our university
          Community
        </p>
        <ul>
          <li>
            {" "}
            <img src={mail} alt="mail" /> Contact@Contact.dev
          </li>
          <li>
            {" "}
            <img src={phone} alt="phone" /> +1 132-456-7890
          </li>
          <li>
            {" "}
            <img src={location} alt="location" />
            77 Massachusetts Ave, Cambridge
            <br /> MA 02139, United States
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label htmlFor="">Your name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter Your Name"
            required
            id=""
          />
          <label htmlFor="">Your Phone</label>
          <input
            type="tel"
            name="phone"
            placeholder="Enter Your Phone "
            required
            id=""
          />
          <label htmlFor="">Your Message</label>
          <textarea
            name="message"
            placeholder="Enter Your Message"
            required
            rows="6"
          ></textarea>
          <button type="submit" className="btn dark-btn">
            Submit <img src={whiteArrow} alt="whiteArrow" />
          </button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Contact;
